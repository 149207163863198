import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Fetch Invoices
export const fetchCenters = createAsyncThunk('get/centers', async params => {
  try {
    const response = await axios.get("http://localhost/api/centers", params)

    return response.data.data
  } catch (error) {
    return error.message
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

export const districtSlice = createSlice({
  name: 'centers',
  initialState: {
    data: [], // Array to store districts
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchCenters.pending, (state) => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchCenters.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving districts to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchCenters.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!';
      state.loading = false // Set loading false to hide loading screen
    })
  }
});

export default districtSlice.reducer
