import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

// ** Fetch Roles
export const fetchRoles = createAsyncThunk('roles/fetchData', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(apiUrl + '/system/roles', params)
    return response.data.data
  } catch (error) {
    // return error.message
     // Check if error is rejected with value
     if (error.response && error.response.status === 422) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      if(error.response.status===403){
        return rejectWithValue(error.response.status)
      }
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})

// ** Add Role
export const addRole = createAsyncThunk('roles/addRole', async (data, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await axios.post(apiUrl + '/system/roles', data)

    dispatch(fetchRoles(getState().roles?.params))
    return response.data
  } catch (error) {
    // Check if error is rejected with value
    if (error.response && error.response.status === 422) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})

// ** Delete Role
export const deleteRole = createAsyncThunk('roles/deleteRole', async (id, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await axios.delete(apiUrl + `/system/roles/${id}`)
    dispatch(fetchRoles(getState().roles?.params))
    return id;
  } catch (error) {
    // Check if error is rejected with value
    if (error.response && error.response.status === 400) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})

// ** role id wise Role data
export const IdWiseRoleData = createAsyncThunk('roles/getRoleData', async roleId => {
  try {
    const response = await axios.get(apiUrl + `/system/roles/${roleId}`)
    return response.data.data
  } catch (error) {
    // Check if error is rejected with value
    if (error.response && error.response.status === 422) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      return rejectWithValue(error.message || 'An error occurred')
    }  }
})

// ** Edit Role
export const editRole = createAsyncThunk('roles/editRole', async (data, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await axios.put(apiUrl + `/system/roles/${data.roleId}`, data)
    dispatch(fetchRoles(getState().roles?.params))
    return response.data.data
  } catch (error) {
    // Check if error is rejected with value
    if (error.response && error.response.status === 422) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

export const appRolesSlice = createSlice({
  name: 'roles',
  initialState: {
    data: [], // Array to store roles
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchRoles.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving roles to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchRoles.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!'
      state.loading = false // Set loading false to hide loading screen
    })

    builder.addCase(deleteRole.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      state.loading = false // Set loading false to hide loading screen
      state.roles = state.roles?.filter(role => role.id !== action.payload)
    })

    // API response received state (FAILURE)
    builder.addCase(deleteRole.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!'
      state.loading = false // Set loading false to hide loading screen
    })

    builder.addCase(IdWiseRoleData.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(IdWiseRoleData.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(IdWiseRoleData.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!'
      state.loading = false // Set loading false to hide loading screen
    })
  }
})

export default appRolesSlice.reducer
