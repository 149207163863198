// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

const capitalizeWords = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// ** Fetch Permissions for create roles ui
export const fetchPermissions = createAsyncThunk('get/permissions', async () => {
  try {
    const response = await axios.get(apiUrl + '/system/permissions')
    return response.data.data.data
  } catch (error) {
    return error.message
  }
})

// ** Fetch Permissions for permission table
export const fetchPermissions_forpermissiontable = createAsyncThunk('get/permissions', async () => {
  try {
    const response = await axios.get(apiUrl + '/system/permissions')
    let permission_array = []
    let count = 1
    Object.entries(response.data.data.data).map(([roles_category, actionsList]) => {
      let permission_data = {}
      if (roles_category && roles_category != undefined) {
        permission_data.entities = capitalizeWords(roles_category)
        permission_data.SN = count++
        permission_data.id = []
        permission_data.permissions = []
        actionsList &&
          actionsList.length &&
          actionsList.map(x => {
            permission_data.id.push(x.id)
            permission_data.permissions.push(capitalizeWords(x.name))
          })
      }
      permission_array.push(permission_data)
    })
    return permission_array
  } catch (error) {
    return error.message
  }
})

// ** Reload Permissions
export const reloadPermissions = createAsyncThunk(
  'appPermissions/reloadpermissions',
  async (_, { getState, dispatch }) => {
    try {
      const response = await axios.get(apiUrl + '/reload-application-permission')
      dispatch(fetchPermissions_forpermissiontable(getState().appPermissions?.params))
      return response.data
    } catch (error) {
      // Check if error is rejected with value
      if (error.response && error.response.status === 422) {
        // Extract error message and errors from response data
        const { message, errors } = error.response.data

        // Construct error object with message and errors
        const errorObj = { message, errors }

        // Reject the promise with the error object
        return rejectWithValue(errorObj)
      } else {
        // If there's an error other than 422, reject the promise with the error message
        return rejectWithValue(error.message || 'An error occurred')
      }
    }
  }
)

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  //for no header permission data
  if (payload) {
    state.data = payload
    state.total = payload.length
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

export const appPermissionsSlice = createSlice({
  name: 'appPermissions',
  initialState: {
    data: [], // Array to store permissions
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchPermissions_forpermissiontable.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchPermissions_forpermissiontable.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving districts to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchPermissions_forpermissiontable.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch permissions!'
      state.loading = false // Set loading false to hide loading screen
    })

    // API fetching state
    builder.addCase(reloadPermissions.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(reloadPermissions.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      // saveToRedux(state, action.payload) // Saving districts to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(reloadPermissions.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!'
      state.loading = false // Set loading false to hide loading screen
    })
  }
})

export default appPermissionsSlice.reducer
