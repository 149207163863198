import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Fetch Invoices
export const fetchCenterTypes = createAsyncThunk(
  'get/centerTypes',
  async (payload, {rejectWith, getState, dispatch}) => {
    try {
      let url = "http://localhost/api/centerTypes"
      const response = await axios.get(url)

      return response.data
    } catch (error) {
      return error.message
    }
  }
)

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
  } else {
    state.message = payload
  }
}

export const centerTypeSlice = createSlice({
  name: 'centerTypes',
  initialState: {
    data: [], // Array to store districts
    message: '', // Message from API response
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchCenterTypes.pending, (state) => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchCenterTypes.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving districts to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchCenterTypes.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!';
      state.loading = false // Set loading false to hide loading screen
    })
  }
});

export default centerTypeSlice.reducer
