// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { identifier } from 'stylis'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

// ** Get Loan Data from ID
export const getLoanDataFromID = createAsyncThunk(
  'appLoan/getloaddatafromid',
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      console.log('params a getLoanDataFromID', params)
      const headers = {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_AUTHORIZATION,
        'Autopal-Instance-Id': process.env.REACT_APP_AUTOPAL_INSTANCE_ID
      }
      console.log(headers)
      const response = await axios.post(
        'https://loanpro.simnang.com/api/public/api/1/Loans/Autopal.Search()?$start=0',
        params,
        { headers }
      )
      // dispatch(fetchPortfolio(getState().appPortfolio?.params))
      console.log('@@response', response)
      return response
    } catch (error) {
      // Check if error is rejected with value
      if (error.response && error.response.status === 422) {
        // Extract error message and errors from response data
        const { message, errors } = error.response.data

        // Construct error object with message and errors
        const errorObj = { message, errors }

        // Reject the promise with the error object
        return rejectWithValue(errorObj)
      } else {
        // If there's an error other than 422, reject the promise with the error message
        return rejectWithValue(error.message || 'An error occurred')
      }
    }
  }
)

// ** Save Searched Loan Data
export const saveLoanDataToDB = createAsyncThunk('appLoan/savedatatodb', async (id, { getState, dispatch }) => {
  try {
    console.log('saving')
    console.log(accessToken)
    const response = await axios.post(apiUrl + '/save-to-db', { loan_id: id })
    return response.data
  } catch (error) {
    // Check if error is rejected with value
    if (error.response && error.response.status === 422) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

export const appLoanSlice = createSlice({
  name: 'appLoan',
  initialState: {
    data: [], // Array to store loan data
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    // builder.addCase(fetchPortfolio.pending, (state) => {
    //   state.loading = true // Set loading true to show loading screen
    // })

    // // API response received state (SUCCESS)
    // builder.addCase(fetchPortfolio.fulfilled, (state, action) => {
    //   // Assuming action.payload is the response from the API
    //   saveToRedux(state, action.payload) // Saving districts to redux
    //   state.loading = false // Set loading false to hide loading screen
    // })

    // // API response received state (FAILURE)
    // builder.addCase(fetchPortfolio.rejected, (state, action) => {
    //   state.message = action.payload?.message || 'Failed to fetch data!';
    //   state.loading = false // Set loading false to hide loading screen
    // })

    builder.addCase(getLoanDataFromID.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(getLoanDataFromID.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving districts to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(getLoanDataFromID.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!'
      state.loading = false // Set loading false to hide loading screen
    })
  }
})

export default appLoanSlice.reducer
