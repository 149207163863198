const apiUrl = process.env.REACT_APP_API_URL

export default {
  meEndpoint: apiUrl + '/authenticate',
  loginEndpoint: apiUrl + '/login',
  refreshEndpoint: apiUrl + '/refresh',
  registerEndpoint: '/jwt/register',
  storageTokenKeyName: 'accessToken',
  onTokenExpiration: 'refreshToken' // logout | refreshToken
}
