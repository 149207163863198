// src/context/JobEventContext.js
import React, { createContext, useState, useContext } from 'react'

const JobEventContext = createContext()

export const useJobEvents = () => {
  return useContext(JobEventContext)
}

export const JobEventProvider = ({ children }) => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)

  const addEvent = event => {
    setEvents(prevEvents => [...prevEvents, event])
  }

  const removeEvent = () => {
    setEvents(prevEvents => prevEvents.slice(1))
  }

  const toggleLoadingState = () => {
    setLoading(prevLoading => !prevLoading)
  }
  const resetEvents = () => {
    setEvents([])
  }

  return (
    <JobEventContext.Provider value={{ events, addEvent, removeEvent, toggleLoadingState, loading, resetEvents }}>
      {children}
    </JobEventContext.Provider>
  )
}
