import React, { lazy, Suspense } from 'react'
import { store } from 'src/store'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import ThemeComponent from './@core/theme/ThemeComponent'
import ReactHotToast from './@core/styles/libs/react-hot-toast'
import { Toaster } from 'react-hot-toast'
import { SettingsConsumer, SettingsProvider } from './@core/context/settingsContext'
import { AuthProvider } from './context/AuthContext'
import GuestGuard from './@core/components/auth/GuestGuard'
import Spinner from './@core/components/spinner'
import AuthGuard from './@core/components/auth/AuthGuard'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'src/iconify-bundle/icons-bundle-react'
import { AbilityContext } from 'src/layouts/components/acl/Can'
import ability from './configs/ability'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** Global css styles
import './globals.css'
import BlankLayout from './@core/layouts/BlankLayout'
import Error404 from './pages/404'
import Login from './pages/login'
import { JobEventProvider } from './context/JobEventContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
const LazyApp = lazy(() => import('./App'))
root.render(
  <BrowserRouter>
    <JobEventProvider>
      <Provider store={store}>
        <AuthProvider>
          <Suspense fallback={<Spinner />}>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <AbilityContext.Provider value={ability}>
                      <ThemeComponent settings={settings}>
                        <LazyApp />
                        <ReactHotToast>
                          <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                        </ReactHotToast>
                      </ThemeComponent>
                    </AbilityContext.Provider>
                  )
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </Suspense>
        </AuthProvider>
      </Provider>
    </JobEventProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
