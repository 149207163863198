import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

// ** Fetch accessToken and set it in axios default headers
const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
if (accessToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

// ** Define API URL
const apiUrl = process.env.REACT_APP_API_URL

// ** Fetch Loans
export const fetchLoans = createAsyncThunk('loans/fetchData', async params => {
  try {
    const response = await axios.get(apiUrl + '/get/loan-data', { params })
    return response.data.data
  } catch (error) {
    return error.message
  }
})

export const fetchSingleLoan = createAsyncThunk('loans/fetchSingleLoan', async id => {
  try {
    const response = await axios.get(apiUrl + `/loan/${id}`)
    return response.data.data
  } catch (error) {
    return error.message
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

const loanSlice = createSlice({
  name: 'loans',
  initialState: {
    data: [], // Array to store loans
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchLoans.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchLoans.fulfilled, (state, action) => {
        // Assuming action.payload is the response from the API
        saveToRedux(state, action.payload) // Saving roles to redux
        state.loading = false
      })
      .addCase(fetchLoans.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(fetchSingleLoan.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchSingleLoan.fulfilled, (state, action) => {
        // Assuming action.payload is the response from the API
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchSingleLoan.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export default loanSlice.reducer
