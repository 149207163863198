// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Next Import

// ** Axios
import axios from 'axios'

// ** Config
import authConfig from 'src/configs/auth'
import { useLocation, useNavigate } from 'react-router-dom'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  loginLoading: false,
  setUser: () => null,
  setLoading: () => Boolean,
  setLoginLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const useQueryParams = () => {
  const { search } = useLocation()
  return new URLSearchParams(search)
}

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [accessToken, setAccessToken] = useState('')
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [loginLoading, setLoginLoading] = useState(defaultProvider.loginLoading)

  // ** Hooks
  const navigate = useNavigate()
  const queryParams = useQueryParams()

  useEffect(() => {
    const accessToken = queryParams.get('accessToken')
    const refreshToken = queryParams.get('refreshToken')
    const userDataString = queryParams.get('userData')

    if (accessToken && refreshToken && userDataString) {
      // Store tokens and user data in localStorage
      sessionStorage.setItem(authConfig.storageTokenKeyName, accessToken)
      sessionStorage.setItem('refreshToken', refreshToken)
      sessionStorage.setItem('userData', userDataString)
      sessionStorage.setItem('isRedirected', 'true')

      // Clear the query parameters
      window.history.replaceState({}, '', window.location.pathname)
      const userData = JSON.parse(userDataString)

      // Navigate to the route based on access
      userData?.portfolios?.some(portfolio => portfolio.is_main === true)
        ? navigate('/report/main')
        : navigate('/report/personal-portfolio')
    }
  }, [queryParams])

  useEffect(() => {
    const storedToken =
      window.localStorage.getItem(authConfig.storageTokenKeyName) ||
      sessionStorage.getItem(authConfig.storageTokenKeyName)
    const userData = window.localStorage.getItem('userData') || sessionStorage.getItem('userData')
    const pathname = window.location.pathname || sessionStorage.getItem('pathname')
    // Define base routes that should be exempt from redirection
    const exemptRoutes = ['/forgot-password', '/reset-password']

    // Check if the current pathname starts with any of the exempt routes
    const isAuthExemptRoute = exemptRoutes.some(route => pathname.startsWith(route))
    if ((!userData || !storedToken) && !isAuthExemptRoute) {
      console.log('redirecting to login , authcontext')
      navigate('/login')
    } else {
      setAccessToken(storedToken)
      setUser(JSON.parse(userData))
    }

    /*Retry Mechanism
    1. Request A fails with a 401.
    2. If no refresh is in progress, it starts the token refresh process.
    3. If request B fails with a 401 while A is refreshing the token, B's retry logic is queued (it adds a callback via subscribeTokenRefresh).
    4. Once A’s refresh completes, the onRrefreshed function is called, which goes through all the queued requests, including B.
    5. Each queued request (like B) retries using the new token.
    The retry logic is contained within the Promise that wraps the queuing process, and it resolves once the refresh completes, retrying the original request with the updated access token.      */

    // Set up Axios interceptor (Add the interceptor)
    let isRefreshing = false // Track if token refresh is in progress
    let refreshSubscribers = [] // Queue of failed requests waiting for the token refresh

    // Function to subscribe to the refresh process (push a request to the queue)
    const subscribeTokenRefresh = cb => {
      refreshSubscribers.push(cb)
    }

    // Function to notify all subscribers when a new token is available
    const onRrefreshed = newToken => {
      refreshSubscribers.forEach(cb => cb(newToken))
      refreshSubscribers = [] // Clear the queue after all subscribers are resolved
    }

    // Set up Axios interceptor (Add the interceptor)
    const interceptor = axios.interceptors.response.use(
      response => response, // If the response is successful, just return the response
      async error => {
        const originalRequest = error.config // Save the original request

        // Check for 401 unauthenticated error
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true // Mark the request as retried

          if (!isRefreshing) {
            isRefreshing = true // Start token refresh process

            try {
              const refreshToken = window.localStorage.getItem('refreshToken')
              const { data } = await axios.post(authConfig.refreshEndpoint, { refresh_token: refreshToken })

              // Update the stored tokens with new ones
              if (sessionStorage.getItem('isRedirected')) {
                sessionStorage.setItem(authConfig.storageTokenKeyName, data.data.accessToken)
                sessionStorage.setItem('refreshToken', data.data.refreshToken)
              } else {
                window.localStorage.setItem(authConfig.storageTokenKeyName, data.data.accessToken)
                window.localStorage.setItem('refreshToken', data.data.refreshToken)
              }

              setAccessToken(data.data.accessToken)

              // Notify all subscribers (queued requests) with the new token
              onRrefreshed(data.data.accessToken)

              isRefreshing = false // Reset the refresh flag
              window.location.reload()
              // Retry the original request with the new token
              originalRequest.headers['Authorization'] = `Bearer ${data.data.accessToken}`
              return axios(originalRequest)
            } catch (refreshError) {
              // If refreshing the token fails, log out the user
              handleLogout()
              return Promise.reject(refreshError)
            }
          } else {
            // If a token refresh is already in progress, queue the request
            return new Promise(resolve => {
              subscribeTokenRefresh(newToken => {
                // Retry the original request with the new token once it's available
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`
                resolve(axios(originalRequest))
              })
            })
          }
        }

        // If the error is not a 401 or the request has already been retried, reject the promise with the error
        return Promise.reject(error)
      }
    )
    // Cleanup function to eject the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor)
    }
  }, [])

  const handleLogin = async (params, errorCallback) => {
    setLoginLoading(true)
    try {
      console.log(params)
      const response = await axios.post(authConfig.loginEndpoint, params)
      const { accessToken, refreshToken, userData, redirectUrl } = response.data.data
      // Check if the page is loaded within an iframe
      if (window.self !== window.top) {
        // Redirect the parent window to the redirect URL
        const query = new URLSearchParams({
          accessToken,
          refreshToken,
          userData: JSON.stringify(userData)
        }).toString()

        const redirectWithParams = `${redirectUrl}?${query}`
        window.top.location.href = redirectWithParams
      } else {
        if (params.rememberMe) {
          window.localStorage.setItem(authConfig.storageTokenKeyName, accessToken)
          window.localStorage.setItem('refreshToken', refreshToken)
          window.localStorage.setItem('userData', JSON.stringify(userData))
        } else {
          sessionStorage.setItem(authConfig.storageTokenKeyName, accessToken)
          sessionStorage.setItem('refreshToken', refreshToken)
          sessionStorage.setItem('userData', JSON.stringify(userData))
        }
        setUser(userData)
        setAccessToken(accessToken)
        userData?.portfolios?.some(portfolio => portfolio.is_main === true)
          ? navigate('/report/main')
          : navigate('/report/personal-portfolio')
      }
    } catch (err) {
      if (errorCallback) errorCallback(err)
    } finally {
      setLoginLoading(false)
    }
  }

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    window.localStorage.removeItem('refreshToken')
    const isRedirected = sessionStorage.getItem('isRedirected')
    if (isRedirected) {
      sessionStorage.removeItem('isRedirected')
      sessionStorage.removeItem(authConfig.storageTokenKeyName)
      sessionStorage.removeItem('refreshToken')
      sessionStorage.removeItem('userData')
      window.top.location.href = 'https://web.loanformobilehome.com/'
    }
    navigate('/login')
  }

  const values = {
    user,
    accessToken,
    loading,
    loginLoading,
    setUser,
    setLoading,
    setLoginLoading,
    login: handleLogin,
    logout: handleLogout
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
