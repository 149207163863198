// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'

// ** Reducers
import centers from 'src/store/apps/centers'
import centerTypes from 'src/store/apps/center-types'
import districts from 'src/store/apps/districts'
import designations from 'src/store/apps/designations'
import staffs from 'src/store/apps/staffs'
import appUsers from 'src/store/apps/user'
import roles from 'src/store/apps/roles'
import appPermissions from 'src/store/apps/permissions'
import appPortfolio from 'src/store/apps/portfolio'
import appLoan from 'src/store/apps/loanpro'
import loans from 'src/store/apps/loan'
import appInterestConfiguration from 'src/store/apps/interestConfiguration'
import appUserContribution from 'src/store/apps/userContribution'

export const store = configureStore({
  reducer: {
    centers,
    centerTypes,
    districts,
    designations,
    staffs,
    appUsers,
    roles,
    appPermissions,
    appPortfolio,
    appLoan,
    loans,
    appInterestConfiguration,
    appUserContribution
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})
