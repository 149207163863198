import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Fetch Designations
export const fetchDesignations = createAsyncThunk('get/designations', async params => {
  try {
    const response = await axios.get("http://localhost/api/designations", {params})
    return response.data
  } catch (error) {
    return error.message
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
  } else {
    state.message = payload
  }
}

export const designationSlice = createSlice({
  name: 'designations',
  initialState: {
    data: [], // Array to store designations
    message: '', // Message from API response
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchDesignations.pending, (state) => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchDesignations.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving designations to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchDesignations.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!';
      state.loading = false // Set loading false to hide loading screen
    })
  }
});

export default designationSlice.reducer
