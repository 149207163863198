import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL;

// ** Fetch Staffs
export const fetchStaffs = createAsyncThunk('get/staffs', async params => {
  try {
    const response = await axios.get(apiUrl+"/system/staffs", params)
// console.log("@@response",response)
    return response.data.data
  } catch (error) {
    return error.message
  }
})

// ** Add User
export const addStaff = createAsyncThunk('appUsers/addStaff', async (data, { getState, dispatch }) => {
  // console.log("@@HERE",data)
  const response = await axios.post(apiUrl+"/apps/users/add-staff", {
    data
  })
  dispatch(fetchStaffs(getState().staffs.params))

  return response.data
})

// ** Delete Staffs
export const deleteStaff = createAsyncThunk('appUsers/deleteStaff', async (id, { getState, dispatch }) => {
  const response = await axios.delete(apiUrl+"/system/staffs/delete", {
    data: id
  })
  dispatch(fetchStaffs(getState().staffs.params))

  return response.data
})

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

// export const appStaffsSlice = createSlice({
//   name: 'staffs',
//   initialState: {
//     data: [],
//     total: 1,
//     params: {},
//     allData: []
//   },
//   reducers: {},
//   extraReducers: builder => {
//     builder.addCase(fetchStaffs.fulfilled, (state, action) => {
//       state.data = action.payload.users
//       state.total = action.payload.total
//       state.params = action.payload.params
//       state.allData = action.payload.allData
//     })
//   }
// })

export const appStaffsSlice = createSlice({
  name: 'staffs',
  initialState: {
    data: [], // Array to store districts
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchStaffs.pending, (state) => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchStaffs.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving districts to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchStaffs.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!';
      state.loading = false // Set loading false to hide loading screen
    })
  }
});

export default appStaffsSlice.reducer
