// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

// ** Fetch Portfolio
export const fetchPortfolio = createAsyncThunk('get/portfolio', async route => {
  try {
    const response = await axios.post(apiUrl + '/dropdown-portfolio', { route: route })
    return response.data
  } catch (error) {
    return error.message
  }
})

// ** Refresh new Portfolio
export const refresh_Portfolio = createAsyncThunk(
  'appPortfolio/refreshPortfolio',
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(apiUrl + '/fetch/portfolios', params)
      const portfoliodata = dispatch(fetchPortfolio(getState().appPortfolio?.params))
      return portfoliodata
    } catch (error) {
      // Check if error is rejected with value
      if (error.response && error.response.status === 422) {
        // Extract error message and errors from response data
        const { message, errors } = error.response.data

        // Construct error object with message and errors
        const errorObj = { message, errors }

        // Reject the promise with the error object
        return rejectWithValue(errorObj)
      } else {
        // If there's an error other than 422, reject the promise with the error message
        return rejectWithValue(error.message || 'An error occurred')
      }
    }
  }
)

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

export const appPortfolioSlice = createSlice({
  name: 'appPortfolio',
  initialState: {
    data: [], // Array to store Portfolio
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchPortfolio.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchPortfolio.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving districts to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchPortfolio.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!'
      state.loading = false // Set loading false to hide loading screen
    })

    builder.addCase(refresh_Portfolio.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(refresh_Portfolio.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving districts to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(refresh_Portfolio.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!'
      state.loading = false // Set loading false to hide loading screen
    })
  }
})

export default appPortfolioSlice.reducer
