import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

// ** Add Interest Configuration
export const addInterestRateData = createAsyncThunk('appInterestConfiguration/addInterestRateData', async (data, { getState, dispatch }) => {
  try {
    const response = await axios.post(apiUrl + '/interest-rate', data)
    dispatch(fetchInterestRateData(getState().appInterestConfiguration?.params))
    return response.data
  } catch (error) {
    // Check if error is rejected with value
    if (error.response && error.response.status === 422) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})

// ** Fetch Interest Configuration Users
export const fetchInterestRateData = createAsyncThunk('appInterestConfiguration/fetchInterestConfiguration', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(apiUrl + '/interest-rate', params)
    return response.data
  } catch (error) {
    // return error.message
    // Check if error is rejected with value
    if (error.response && error.response.status === 422) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      if (error.response.status === 403) {
        return rejectWithValue(error.response.status)
      }
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})


// ** Edit Interest Data
export const editInterestRateData = createAsyncThunk('appInterestConfiguration/editInterestRateData', async (data, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await axios.put(apiUrl + `/interest-rate/${data.id}`, data)
    dispatch(fetchInterestRateData(getState().appUsers?.params))
    return response.data.data
  } catch (error) {
    // Check if error is rejected with value
    if (error.response && error.response.status === 422) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})

// ** Delete Interest Rate Data
export const deleteInterestRateData = createAsyncThunk('appInterestConfiguration/deleteInterestRateData', async (id, { getState, dispatch }) => {
  try {
    const response = await axios.delete(apiUrl + `/interest-rate/${id}`)
    dispatch(fetchInterestRateData(getState().appUsers?.params))
    return id;
  } catch (error) {
    // Check if error is rejected with value
    if (error.response && error.response.status === 500) {
      // Extract error message and errors from response data
      const { message, errors } = error.response.data

      // Construct error object with message and errors
      const errorObj = { message, errors }

      // Reject the promise with the error object
      return rejectWithValue(errorObj)
    } else {
      // If there's an error other than 422, reject the promise with the error message
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})



const saveToRedux = (state, payload) => {
  if (state?.data === payload?.data) return

  if (payload?.data) {
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

export const appInterestConfigurationSlice = createSlice({
  name: 'appInterestConfiguration',
  initialState: {
    data: [], // Array to store Interest Configuration details
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchInterestRateData.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchInterestRateData.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving Interest Configuration to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchInterestRateData.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch user!'
      state.loading = false // Set loading false to hide loading screen
    })

    builder.addCase(deleteInterestRateData.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(deleteInterestRateData.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      state.loading = false // Set loading false to hide loading screen
      state.roles = state.appUsers?.filter(user => user.id !== action.payload)
    })

    // API response received state (FAILURE)
    builder.addCase(deleteInterestRateData.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to delete!'
      state.loading = false // Set loading false to hide loading screen
    })

    builder.addCase(addInterestRateData.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(addInterestRateData.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving users to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(addInterestRateData.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to add user!'
      state.loading = false // Set loading false to hide loading screen
    })

    builder.addCase(editInterestRateData.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(editInterestRateData.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving users to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(editInterestRateData.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to edit!'
      state.loading = false // Set loading false to hide loading screen
    })
  }
})

export default appInterestConfigurationSlice.reducer
